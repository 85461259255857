import type { NextPage } from 'next'
import { useRouter } from 'next/router'

const Home: NextPage = () => {
  const router = useRouter()
  if (router.isReady) {
    router.replace('/contracts').then()
  }
  return null
}
/* eslint-disable-next-line import/no-default-export */
export default Home
